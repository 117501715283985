import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteOperationXray, DownloadOperationXray, ListOperationXrays } from "gc-web-proto/galaxycompletepb/apipb/support_api_pb";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { SupportQueryKeys, SupportMutationKeys } from "./SupportCommon";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useDownloadFileFromURL } from "../../common/misc";
import { useGlobalProgressState } from "../core/progress/GlobalProgressState";
export const useListOperationXrays = (opId: number) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListOperationXrays.Request().setOperationId(opId);
        const res = await apis.supportService.listOperationXrays(req, null);
        return res?.toObject();
    };
    return useQuery({
        queryKey: [SupportQueryKeys.listOperationXrays, opId],
        queryFn: queryFn,
    });
};

export const useDownloadOperationXray = () => {
    const apis = useGrpcApiStore();
    const downloadFile = useDownloadFileFromURL();
    const globalProgressState = useGlobalProgressState();
    return async (xrayId: string) => {
        const req = new DownloadOperationXray.Request().setXrayId(xrayId);
        const res = await globalProgressState.track(apis.supportService.downloadOperationXray(req, null), "Downloading X-Ray...");
        if (res) {
            const url = res.getDownloadPath();
            const fileName = res.getFileName();
            await downloadFile(url, fileName);
        }
    };
};

export const useDeleteOperationXray = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const queryFn = async (xrayId: string) => {
        const req = new DeleteOperationXray.Request().setXrayId(xrayId);
        const res = await apis.supportService.deleteOperationXray(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationFn: queryFn,
        mutationKey: [SupportMutationKeys.deleteOperationXray],
    });
};
