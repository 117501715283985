// ======================
// ProjectMigrateOpsScreens
// ======================

import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { MIGRATE_OPS_SUBROUTE } from "../app/AppRoutes";
import { MigrateOpsListView } from "./MigrateOpsListView";
import { MigrateOpsNewOperationBuilderPage } from "./MigrateOpsNewOperationBuilder";
import { MigrateOpsNewOperationPage } from "./MigrateOpsNewOperationPage";
import { MigrateOpsDetailsView } from "./details/MigrateOpsDetailsView";
import { MigrateOpsXraysView } from "./details/MigrateOpsXraysView";
import { MigrateOpsWizardView } from "./wizard/MigrateOpsWizardView";

interface ProjectMigrateOpsScreensProps {}

export const ProjectMigrateOpsScreens: React.FC<ProjectMigrateOpsScreensProps> = (p) => {
    return (
        <Routes>
            <Route index element={<MigrateOpsListView />} />
            <Route path={`:opId`}>
                <Route index element={<MigrateOpsDetailsView />} />
                <Route path={MIGRATE_OPS_SUBROUTE.XRAYS} element={<MigrateOpsXraysView />} />
            </Route>
            <Route path={MIGRATE_OPS_SUBROUTE.CREATE} element={<MigrateOpsNewOperationPage />} />
            <Route path={MIGRATE_OPS_SUBROUTE.BUILDER} element={<MigrateOpsNewOperationBuilderPage />} />
            <Route path={`${MIGRATE_OPS_SUBROUTE.WIZARD}/:recipe`} element={<MigrateOpsWizardView />} />
        </Routes>
    );
};
