import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { Button, IconButton, SvgIcon, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigateToOpDetails } from "../MigrateOpsCommon";
import { BackButton } from "../../../common/CommonButtons";
import { useGetMigrateOpDetails } from "../migrate_ops_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { useDownloadOperationXray, useDeleteOperationXray, useListOperationXrays } from "../../support/support_hooks";
import { QueryTable } from "../../../common/table/QueryTable";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { OperationXray } from "gc-web-proto/galaxycompletepb/apipb/domainpb/support_pb";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { useState } from "react";
import { MdDownload, MdDelete, MdAdd } from "react-icons/md";
import { Stack } from "@mui/material";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { useGlobalProgressState } from "../../core/progress/GlobalProgressState";
import { GetOperationXRay } from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import { useGrpcApiStore } from "../../grpc/grpcApiStore";

export const MigrateOpsXraysView: React.FC = () => {
    const { opId } = useParams();
    const goBackToDetails = useNavigateToOpDetails();
    const opDetails = useGetMigrateOpDetails(Number(opId));
    const xrays = useListOperationXrays(Number(opId));
    const globalDialogState = useGlobalDialogState();
    const globalProgressState = useGlobalProgressState();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const apis = useGrpcApiStore();
    const downloadXray = useDownloadOperationXray();
    const deleteXray = useDeleteOperationXray();
    const createXray = async () => {
        const res = await globalProgressState.track(
            apis.operationService.getOperationXRay(new GetOperationXRay.Request().setOperationId(Number(opId)), null),
            "Creating X-Ray..."
        );
        if (res) {
            xrays.refetch();
        }
    };

    const handleDeleteXray = async (xrayId: string) => {
        const confirmed = await globalDialogState.addConfirmDialog({
            title: "Delete X-Ray",
            message: "Are you sure you want to delete this X-Ray?",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            deleteXray.mutate(xrayId, {
                onSuccess: () => {
                    xrays.refetch();
                },
            });
        }
    };

    const columnHelper = createColumnHelper<OperationXray.AsObject>();

    const columns = [
        columnHelper.accessor((r) => r.filename, {
            header: "Filename",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.uploadDate, {
            header: "Upload Date",
            cell: (props) => formatKnownDataType(props.getValue(), KnownDataType.DATE),
        }),
        columnHelper.accessor((r) => r, {
            header: "Actions",
            cell: (props) => (
                <Stack direction={"row"} spacing={1}>
                    <IconButton onClick={() => downloadXray(props.row.original.xrayId)}>
                        <SvgIcon>
                            <MdDownload />
                        </SvgIcon>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteXray(props.row.original.xrayId)}>
                        <SvgIcon>
                            <MdDelete />
                        </SvgIcon>
                    </IconButton>
                </Stack>
            ),
        }),
    ];

    return (
        <ScreenContainer>
            <QueryResultWrapper queryResult={opDetails}>
                <ScreenTitleBar
                    title={opDetails.data?.details.info.name}
                    actions={
                        <Button disabled={!!opDetails.data?.details.info.archivedAt} variant={"contained"} color={"secondary"} onClick={createXray}>
                            Create X-Ray
                        </Button>
                    }
                />
                <BackButton navFunction={() => goBackToDetails(Number(opId))} label={"Back To Operation"} />
                <QueryTable
                    columns={columns}
                    data={xrays.data?.itemsList}
                    emptyTableConfig={{}}
                    error={xrays.error}
                    isLoading={xrays.isLoading}
                    pageCount={xrays.data?.pagerMeta?.totalPages || 0}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    refetch={xrays.refetch}
                />
            </QueryResultWrapper>
        </ScreenContainer>
    );
};
